.UnlimitedPage__InfoBlock {
  em,
  .IconFA {
    color: $secondaryColor;
  }
}

.UnlimitedPage__TitleBlock {
  .Title > span::after {
    border-bottom-color: $secondaryColor;
  }
}