.SubscriptionStatusPage__Option {
  &.--selected {
    background-color: $secondaryColor;
    color: $secondaryColorInverted;

    &::after {
      content: '✓';
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      background: $primaryColor;
      color: $primaryColorInverted;
      border-radius: $radiusSm;
      text-align: center;
      vertical-align: middle;
      line-height: 1.5rem;
    }
  }
}