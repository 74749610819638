.AppFooter {
  color: $primaryColorInverted;
  background: $primaryColor;
  background-image: linear-gradient(to bottom, $primaryColor, $tertiaryColor);
  box-shadow: 0 -2px 6px rgba(#000, 0.2);

  .Title {
    color: $primaryColorInverted;
    text-transform: uppercase;
  }
}
