$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

// Global styles

@import '@/node_modules/react-commons/src/lib/styles';

body {
  font-family: $fontFamily;

  @include isMobileDevice {
    overflow-x: hidden;
  }
}

strong {
  font-weight: $weightMedium;
}

#__next {
  position: relative;
  width: 100%;
}

.App {
  min-height: 100dvh !important;
}

button,
input,
textarea {
  font-family: $fontFamily;
}

.Input {
  font-weight: bold;
}

.Input--outline {
  border-color: inherit;
  background-color: transparent !important;

  input,
  textarea {
    border-color: inherit;
    background-color: transparent !important;
  }
}

.Checkbox {
  input[type=checkbox][disabled] {
    ~ span {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.Button {
  font-weight: bold;

  &.Button--primary,
  &.Button--secondary {
    transition: background-color 400ms ease-out !important;

    @include supportsHover {
      &:hover {
        transition: background-color 100ms ease-out !important;
      }
    }
  }

  &.--iconRight {
    justify-content: flex-start;
    min-width: 165px;

    > span {
      display: flex;
      width: 100%;
      justify-content: flex-start;
    }

    .IconFA {
      padding-left: $marginMd0;
      margin-left: auto;
      box-sizing: content-box;
    }
  }

  &.Button--wideMobile {
    @include isMobileDevice {
      width: 100%;
    }
  }
}

.Buttons--addons {
  flex-wrap: nowrap !important;

  .Button {
    margin-left: -1px !important;
    margin-right: 0 !important;
  }
}

.RouterLink {
  cursor: pointer;
}

.Modal {
  top: $spacing;
  left: $spacing;
  width: calc(100% - #{$spacing} * 2);
  height: calc(100% - #{$spacing} * 2);
}

.ModalCard__Head {
  font-weight: bold;
  padding-bottom: 0;
  border-bottom: none;
}

.Content {
  h3, h4, h5, h6 {
    font-weight: bold;
    margin-top: 1rem;
  }
}

.MenuLabel {
  text-transform: none;
  letter-spacing: normal;
  font-size: 1em;
  font-weight: bold;
}

.MenuList li {
  padding: $marginSm3 0;
  padding-left: $marginMd1;
}

.ympb_usp {
  padding: 1rem !important;
  background: inherit !important;
  color: inherit !important;
  line-height: 1.5 !important;
  z-index: 0 !important;
  height: 180px;
  margin-top: -90px;
  margin-bottom: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  
  @include isMobileDevice {
    height: 140px;
    margin-top: -50px;
  }
}

body[data-premium="true"] .ympb_usp {
  margin-top: 0 !important;
  height: auto !important;
}

.--hideOnMobileDevices {
  @include isMobileDevice {
    display: none !important;
  }
}

.--hideOnDesktopDevices {
  @include isDesktopDevice {
    display: none !important;
  }
}
