@import '@/node_modules/react-commons/src/lib/styles/theme';

// Custom theme variables
$appHeaderBg: #000 !default;
$appHeaderActiveLinkShadowColor: #fff !default;
$skeletonTextBg: rgba(#aaa, 0.5) !default; 

::selection {
  background: rgba($bgColorSecondary, 0.35);
}

.RouterLink {
  color: $secondaryColor;
  font-weight: bold;
  text-decoration: underline;
  transition: color 120ms ease;

  &:hover {
    color: darken($secondaryColor, 2.5%)
  }
}

.Button {
  transition: background-color 120ms ease;

  &:not(.Button--secondary).Button--secondaryOutline {
    color: $secondaryColor;

    &:hover {
      border-color: $secondaryColor;
      color: $secondaryColor;
    }
    &:focus {
      border-color: $secondaryColor;
      color: $secondaryColor;
      &:not(:active) {
        box-shadow: 0 0 0 0.125em rgba($secondaryColor, 0.25);
      }
    }
    &:active {
      border-color: $secondaryColor;
      color: darken($secondaryColor, 5%);
    }
  }

  &.Button--primary,
  &.Button--secondary {
    box-shadow: 0 2px 6px rgba(#000, 0.2);
  }
}

.Button,
.RouterLink {
  &:active {
    transform: translateY(1px);
  }
}

.Dropdown__Content {
  border: solid 1px $primaryColor;
  box-shadow: 0 4px 6px rgba(#000, 0.2);

  // Firefox
  scrollbar-width: auto;
  scrollbar-color: $primaryColor $theme;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    border-radius: $dropdownContentRadius;
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: $dropdownContentRadius;
    background: $theme;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryColor;
    border-radius: $dropdownContentRadius;
    border: 3px solid $theme;
  }
}

.DropdownItem {
  font-weight: bold;
  font-size: 80%;
}

.ModalCard {
  border: solid 2px $primaryColor;
  box-shadow: $shadow;
  border-radius: $radius;
}



@keyframes skeletonLoading {
  0% {
    opacity: 0;
    left: 0;
  }
  25% {
    opacity: 0.65;
    left: 50%;
  }
  50% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 0;
    left: 100%;
  }
}

.--skeletonLoading {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 30%;
    background: linear-gradient(to right, rgba($theme, 0) 0%, rgba($theme, 1) 45%, rgba($theme, 1) 55%, rgba($theme, 0) 100%);
    animation-name: skeletonLoading;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-timing-function: linear;
    transform: translateX(-50%) skewX(-20deg);
  }
}

// Import components
@import '@/components/AdjustableAccordion/index.theme.scss';  
@import '@/components/AppFooter/index.theme.scss';  
@import '@/components/AppHeader/index.theme.scss';  
@import '@/components/ArticleContent/index.theme.scss';
@import '@/components/DailyArchive/index.theme.scss';  
@import '@/components/FeaturedCarousel/index.theme.scss';  
@import '@/components/Friend/index.theme.scss';  
@import '@/components/GenreBreadcrumbs/index.theme.scss';  
@import '@/components/MobileGameButton/index.theme.scss';  
@import '@/components/MobileUpsellBanner/index.theme.scss';  
@import '@/components/Popover/index.theme.scss';  
@import '@/components/RatingsPicker/index.theme.scss';  
@import '@/components/SwGamePlayer/index.theme.scss';  
@import '@/components/SwGameRow/index.theme.scss';  
@import '@/components/SwGameTile/index.theme.scss';  
@import '@/components/Table/index.theme.scss';  
@import '@/components/UpsellBanner/index.theme.scss';  
@import '@/components/UpsellPod/index.theme.scss';  
@import '@/components/MobileUpsellPod/index.theme.scss';  
@import '@/components/pods/DailyBonusPod/index.theme.scss';

// Layouts
// ...

// Pages
@import '@/pages/gamelanding/mobile/[slug]/index.theme.scss';
@import '@/pages/games-for-pc/index.theme.scss';
@import '@/pages/unlimited/index.theme.scss';
@import '@/pages/account/user/[userId]/edit/subscription/index.theme.scss';
