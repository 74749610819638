.DailyArchive__Row {
  &.--hasBorder:not(:last-child) {
    padding-bottom: $spacing;
    border-bottom: solid 1px $borderColor;
  }

  > a {
    &.--today {
      background-color: $primaryColor;
      color: $primaryColorInverted !important;

      > .DailyArchive__Thumbnail {
        color: $secondaryColor;
      }
      
      &.RouterLink {
        color: $primaryColorInverted !important;
        text-decoration: none !important;
      }
    }
  }
}
