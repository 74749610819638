// The contents of this file are globally available in every SASS file automatically, without importing
// This file is meant for global variables, mixins, functions, etc.
// DO NOT put any styles in this file. Global styles belong in app.scss.

/*
 * Global variables
 */

$spacing: 1rem;

$bodyMaxWidth: 1360px;
$bodyMaxWidthUltrahd: 1360px;
$bodyPadding: 1rem;
$bodySmallScreenPadding: 1rem;

$fontFamily: 'Open Sans', sans-serif;
$titleFamily: $fontFamily;
$titleWeight: 800;
$inputFamily: $fontFamily;
$rootFontSizeSm: 16px;
$rootFontSizeLg: 16px;
$fontSizeMd: 14px;

$radius: 7px;
$radiusSm: 5px;
$radiusLg: 9px;
$boxRadius: 5px;

$checkboxBorderRadius: 5px;

$hrHeight: 1px;

@import '@/node_modules/react-commons/src/lib/styles/util/defaults';
@import '@/node_modules/react-commons/src/lib/styles/util/defaultsDerived';
@import '@/node_modules/react-commons/src/lib/styles/util';

/*
 * Mixins
 */

@mixin isMobileDevice {
  @media (hover: none) {
    @content;
  }
}

@mixin isDesktopDevice {
  @media (hover: hover) {
    @content;
  }
}

/*
 * Functions
 */
